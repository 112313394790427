// 金融产品列表
import service from "../request";
const baseUrl = window.globalUrl.HOME_API;

// 金融产品列表查询
export const recordInquiry = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/financial/listSelect`,
    params: params,
  });
};

//金融产品新增接口、编辑
export const AddEdits = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/financial/saveOrUpdate`,
    data: params,
  });
};
// /金融产品详情接口
export const DetailsFinancialProducts = (params) => {
  return service({
    url: `${baseUrl}/trade-website/financial/selectDetails`,
    method: 'get',
    params: params
  })
}
export const repayType = (params) => {
  return service({
    url: `${baseUrl}/admin/dict/type/repay_type`,
    method: 'get',
    params: params
  })
}


//查询所属机构
export const BelongToTheOrganization = (params) => {
  return service({
    url: `${baseUrl}/admin/dept/selectDeptId/${params}`,
    method: 'get',
  })
}

// 字典接口
export const ScreeningCondition = (params) => {
  return service({
    url: `${baseUrl}/admin/dict/type/${params}`,
    method: 'get',
  })
}

//用户申请贷款
export const ApplicationLoan = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/farmerAndCompanyApply`,
    method: 'post',
    data: params
  })
}
// 运营公司申请记录查询接口
export const ApplicationRecordList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/selectRecord`,
    method: 'get',
    params: params
  })
}


// 运营公司查看申请详情接口
export const operateDetails = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/selectDetail`,
    method: 'get',
    params: params
  })
}

//查询所在地区
export const AreaTree = (params) => {
  return service({
    url: `${baseUrl}/admin/area/areaTree`,
    method: 'get',
    params: params
  })
}
//金融产品停用/启用
export const OnOrOff = (params) => {
  return service({
    url: `${baseUrl}/trade-website/financial/openOrStop`,
    method: 'get',
    params: params
  })
}
// 种养品种
export const getBreedInTrust = (data) => {
  return service({
    url: `${baseUrl}/trade-website/company/getDisticntCompanyCrop`,
    method: 'get',
    params: data
  })
}
//根据申请单id删除申请记录

export const DeleteApply = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/deleteId/${params}`,
    method: 'delete',
  })
}
// 运营公司修改回显

export const FormList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/echo`,
    method: 'get',
    params: params,
  })
}
//运营公司修改放款进度
export const ModifyLoan = (data) => {
  return service({
    url: `${baseUrl}/trade-website/apply/updateLoanProgress`,
    method: 'post',
    data: data
  })
}
// 银行修改放款进度
export const bankUpLoan = (data) => {
  return service({
    url: `${baseUrl}/trade-website/apply/updateLoan`,
    method: 'post',
    data: data
  })
}

//导出excel表格  trade-website/apply/exportReport
export const exportExcel = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/exportReport`,
    method: 'get',
    params: params,
    // responseType:'blob',
    responseType: "blob",
  })
}

//查询本行下的银行人员角色用户
export const selectRoleList = (params) => {
  return service({
    url: `${baseUrl}/admin/user/selectRoleList`,
    method: 'get',
    params: params
  })
}
// 申请文件打包下载
export const packageDownload = (params) => {
  return service({
    url: `${baseUrl}/trade-website/financial/packageDownload`,
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
// 申请文件批量下载
export const batchDownload = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/batchDownload`,
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
//申请文件打包下载
export const applyPackage = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/applyPackage`,
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
//生产服务/trade-website/apply/productService

export const ProductionService = (params) => {
  return service({
    url: `${baseUrl}/trade-website/apply/productService`,
    method: 'get',
    params: params
  })
}
// 资源资产
export const ResourceAssets = (data) => {
  return service({
    url: `${baseUrl}/trade-website/apply/resurceAssetCirculation`,
    method: 'post',
    data: data
  })
}
// 免费记账窗口
export const selectExport = (params) => {
  return service({
    url: `${baseUrl}/trade-website/account/selectExport`,
    method: 'get',
    params: params,
  })
}
// 银行主管修改信息

export const editInfo = (params) => {
  return service({
    url: baseUrl + 'admin/user',
    method: 'put',
    data: params
  })
}
// 银行主管删除账户
export const UserDelete = (id) => {
  return service({
    url: `${baseUrl}/admin/user/${id}`,
    method: 'delete',
  })
}
// 银行主管新增账户gitgit
export const UserAdd = (params) => {
  return service({
    url: baseUrl + 'admin/user',
    method: 'post',
    data: params
  })
}
//新增
// 查询所属机构
export const selectDeptName = (params) => {
  return service({
    url: baseUrl + '/trade-website/financial/selectDeptName',
    method: 'get',
    params: params,
    headers: {
      'Authorization': `Bearer ${params.access_token}`,
      // "Identity-Id":params.identityId,
      "Identity-Type":params.identityTypeCode
  }
  })
}
// 父级地区选择
export const parentAreaTree = (params) => {
  return service({
    url: baseUrl + '/admin/area/areaTree',
    method: 'get',
    params: params,
  })
}
//土地类型与品种
export const selectCategoryName = (params) => {
  return service({
    url: baseUrl + '/trade-website/order/selectCategoryName',
    method: 'get',
    params: params,
  })
}
//提交贷款申请
export const farmerAndCompanyApply = (params) => {
  return service({
    url: baseUrl + '/trade-website/apply/farmerAndCompanyApply',
    method: 'post',
    data: params[0],
    headers: {
      'Authorization': `Bearer ${params[1].access_token}`,
      "Identity-Id":params[1].identityId,
      "Identity-Type":params[1].identityType
  }
  })
}
//金融财报
export const newSelectExport = (params) => {
  return service({
    url: baseUrl +'/trade-website/account/selectExport',
    method: 'get',
    params: params[0],
    headers: {
      'Authorization': `Bearer ${params[1].access_token}`,
      "Identity-Id":params[1].identityId,
      // "Content-type": "application/x-www-form-urlencoded"
  }
  })
}
